$('.action-logout').on('click', function() {
  $.ajax({
    url: "/admin/logout",
    type: 'delete',
    success: (data) => {
      location = "admin/dashboard"
    },
    error: (data) => {
      console.log("Error", data)
    }
  })
});